import React from "react";
import "./buttons.scss"


export const Platformbutton = (props)=> {

   
    var _renderbtn = () =>{
        switch (props.type){
            case 'normal':
                return <button type="button" className={`platform platform_mainbtn ${props.size&&'platform_mainbtn__size-small'} ${props.bkg&&'platform_mainbtn__bkg-'+props.bkg}`}   onClick={props.click} disabled={props.disabled}>
                    {props.children && props.children}
                    {props.name}</button>;
            case 'normalicon':
                return <button type="button" className={`platform platform_mainbtnicon ${props.size&&'platform_mainbtnicon__size-small'} ${props.bkg&&'platform_mainbtnicon__bkg-'+props.bkg}`}   onClick={props.click} disabled={props.disabled}>
                    {props.name}
                    <i className={props.icon} />
                </button>;
            case 'submit':
                return <button className="platform platform_mainbtn" type={props.type ? props.type : ''} disabled={props.disabled}>{props.name}</button>
            case 'secondary':
                return  <button className={`platform platform_secbtn ${props.color && ' --'+props.color }`} disabled={props.disabled} onClick={()=> props.click()}>{props.name}</button>
            case 'link':
                return  <a className={`platform platform_link ${props.size?'platform_link__size-small':''} ${props.color && ' --'+props.color }`} disabled={props.disabled} onClick={()=> props.click()}> 
                {props.name} {props.nametwo ? <strong> {props.nametwo}</strong>:''}
                </a>
            case 'darklink':
                return  <button className={`platform platform_darklink ${props.size?'platform_link__size-small':''}`} disabled={props.disabled} onClick={props.click}> 
                {props.name}
                </button>
            case 'slantlink':
                return  <button className={`platform platform_slantlink ${props.color && ' --'+props.color }`} disabled={props.disabled} onClick={props.click}> 
                {props.name}
            </button>
            default:
                return <>Error No Button Type</>
        }
    }

    return (
        _renderbtn()
    )
    
}

export const Back = (props) => {

    return (
        <span className="back" onClick={()=> props.goBack()}><i class="fas fa-angle-left"/><p>Back</p></span>
    )
}

export const Arrowlink = (props) => {

    return (
        <span className="arrowlink" onClick={()=> props.click()}>{props.title} <i class="fas fa-angle-right"/> </span>
    )
}

export const Iconlink = (props) => {

    return (
        <span className="iconlink" onClick={()=> props.click()}> <i class={props.icon}/> {props.title} </span>
    )
}