import "./error.scss"
import Errorsvg from "../../assets/svgs/error.svg"
import { NavLink } from "react-router-dom";
export const Error = ()=> {


    return (
        <div className="error-404-container">
            <div className="error-404-inner">
                <div className="error-404-inner-main">
                    <img src={Errorsvg} alt="Error-404-svg"/>
                    <p>Oops</p>
                    <p>
                    We do not have the page you are currently looking for, maybe it was removed or renamed.
                    </p>
                    <NavLink className="error-404-btn" to="/" style={{textDecoration:'none'}}> Back To Home </NavLink>

                </div>

            </div>

        </div>
    )
}