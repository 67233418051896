import React from "react";
import summer from "../../assets/svgs/summer.svg"
import blogo from "../../assets/svgs/brownlogo.svg"
import flower from "../../assets/svgs/flowers.svg"
import people from "../../assets/svgs/people.svg"
import { Subscribe } from "../../components/common/inputs/search/search";
import './hero.scss'

export const Hero = () => {
    return (

        <section className="hero">
            <img className="hero__flag" src={flower} alt="flower" />
            <img className="hero__people" src={people} alt="people" />
            <div className="hero__inner">
                <span className="hero__top">
                    <span className="hero__innertopp">
                        <h4 className="herob__subject">SUMMER 2023 - Tallinn</h4>
                        <img className="hero__oval" src={summer} alt="oval" />
                        <img className="hero__blogo" src={blogo} alt="logo" />
                        <div className="centralize">
                            <h4 className="herob__subscribe">SUBSCRIBE TO OUR MAILING LIST</h4>
                            <p>Sign-up for priority access to early ticket sales and be the first to hear about line-up and announcements.</p>
                            <span className="hero__innerbottom">
                                <Subscribe />
                                <p>
                                    for partnership and sponsorship, contact <strong> info@balticsafrofest.com </strong>
                                </p>
                            </span>
                        </div>
                    </span>
                </span>
            </div>
        </section>
    )
}