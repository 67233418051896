import React, { useEffect, useState } from "react";
import { Hero } from "../../containers/hero/hero";
import "./styles.scss"

export const Baltics = ()=> {
    const [show, setShow] = useState(0)
    useEffect(()=> {
        let timer1 = setTimeout(() => {
            show  < 2 ? setShow(show +1) : setShow(0)
        }, 2000);
        
        return () => {
            clearTimeout(timer1);
        };
    })
    return (
        <section className="baltics">
            <Hero/>
        </section>
    )
}