import './App.scss';
import { Switch, Route } from 'react-router';
import { Baltics } from './pages/home/baltics';
import { Error } from './pages/Error/error';
function App() {
  return (
    <div className="App">
      <Switch>
      <Route exact path="/" component={Baltics} /> 
        <Route component={Error} />
      </Switch>
    </div>
  );
}

export default App;
