import React, { useState } from "react";
import { Platformbutton } from "../../button/buttons";
import "./search.scss"


export const Subscribe = (props)=> {
    const [event, setEvent] = useState()
    const [location, setLocation] = useState()
    
    return (
        <div className="search --subscribe">
            <div className="search__box">
                <input className="search__input" type="text" name={''} placeholder={''}  />
            </div>
            <div className="search__btn">
                <Platformbutton name="Subscribe" type="normal" bkg="dark" />
            </div>
        </div>
    )
}